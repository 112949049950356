import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Dialog from "@mui/material/Dialog";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { cleverTap } from "../../../data/configs/clevertap";
import { updatePageState } from "../../../data/store/pageSlice";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import {
  getAllAssistants,
  createAssistant,
  deleteAssistant,
  getMentionList,
} from "../../../data/store/assistantsSlice";
import faqContents from "../../../data/contents/faq";
import {
  APP_PAGES,
  ROUTE_PATH,
  CLEVERTAP_EVENT,
  THEME_MODE,
  PAGE_STEPS,
  TOGGLE_BUTTON,
  DEVELOPERSAPI_TEMPLATE,
  DEVELOPERSAPI_DOCS_LINK,
} from "../../../data/configs/constants";
import {
  debounced,
  handleCopyClick,
  log,
  openUrlInNewTab,
} from "../../../data/configs/utils";
import { getUserProfile } from "../../../data/store/authSlice";
import IMG_LOGO_AVATAR_LIGHT from "../../../data/assets/img/logoAvatarLight.png";
import IMG_LOGO_AVATAR_DARK from "../../../data/assets/img/logoAvatarDark.png";
import DEVELOPER_APIS_IMG from "../../../data/assets/img/DeveloperAPIsImg.jpg";
import IMG_ERROR from "../../../data/assets/img/error.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShowMessage from "../../../components/ShowMessage";
import TableLoader from "../../../components/TableLoader";
import ErrorBoundary from "../../../components/ErrorBoundary";
import BasicForm from "../../../components/BasicForm";
import AssistantCard from "../../../components/AssistantCard";
import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  keyframes,
  OutlinedInput,
  Paper,
  Switch,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import StyledAccordion from "../../../components/StyledAccordian";
import StepperUI from "../../../components/StepperUI";
import {
  developersApiDeleteToken,
  developersApiGenerateToken,
  developersApiShowToken,
} from "../../../data/store/developersSlice";
import dayjs from "dayjs";
import CustomHintPopover from "../../../components/CustomHintPopover";
import CopyBlock from "../../../components/common/CopyBlock";

const DevelopersApi = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const workspaceId = useSelector(
    (state) => state.authDetails?.user?.workspace
  );
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [apiKeyGenerateDialog, setApiKeyGenerateDialog] = useState(false);
  const [apiTokenReGenerateDialog, setApiTokenReGenerateDialog] =
    useState(false);
  const [apiKeyDeleteDialog, setApiKeyDeleteDialog] = useState(false);
  const [mentionList, setMentionList] = useState([]);
  const [dialogData, setDialogData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const assistants = useSelector((state) => state.assistantsDetails.assistants);
  const loading = useSelector((state) => state.assistantsDetails.loading);
  const loadError = useSelector((state) => state.assistantsDetails.loadError);
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const userQuota = useSelector((state) => state.authDetails?.user?.userQuota);
  const [numAccordions, setNumAccordions] = useState(5);
  const [generatedApikey, setGeneratedApikey] = useState("");
  const [showApikeyData, setShowApikeyData] = useState({
    keyToShow: "",
    keyUpdatedAt: "",
    tokenGenerated: false,
  });
  const pop = keyframes`
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  `;

  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.DEVELOPERSAPI.PAGE,
        currentActiveModule: APP_PAGES.DEVELOPERSAPI.PAGE,
      })
    );
    dispatch(getAllAssistants());
    if (workspaceId) handleShowToken();
  }, [dispatch]);

  useEffect(() => {
    if (workspaceId) {
      cleverTap.track(CLEVERTAP_EVENT.PAGE_VISIT, {
        workspaceId,
        pageName: APP_PAGES.DEVELOPERSAPI.PAGE,
      });
    }
  }, [workspaceId]);

  const handleRetry = () => {
    dispatch(getAllAssistants());
  };

  const handleShowToken = () => {
    dispatch(developersApiShowToken())
      .unwrap()
      .then((res) => {
        if (res && res?.status === "success") {
          setShowApikeyData({
            ...showApikeyData,
            keyToShow: res?.data?.keyToShow ?? "",
            keyUpdatedAt: res?.data?.keyUpdatedAt ?? "",
            tokenGenerated: res?.data?.tokenGenerated ?? "",
          });
        }
      })
      .catch((err) => {
        // Handle error if necessary
      });
  };
  const handleGenerateToken = (regenerated = false) => {
    dispatch(developersApiGenerateToken())
      .unwrap()
      .then((res) => {
        if (res && res?.status === "success") {
          setGeneratedApikey(res?.data?.apiKey);
          setApiKeyGenerateDialog(true);
          setShowApikeyData({
            ...showApikeyData,
            keyToShow: res?.data?.keyToShow ?? "",
            keyUpdatedAt: res?.data?.keyUpdatedAt ?? "",
            tokenGenerated: res?.data?.tokenGenerated ?? "",
          });
          regenerated
            ? enqueueSnackbar("API Token Regenerated Successfully !", {
                variant: "success",
              })
            : enqueueSnackbar("API Token Generated Successfully !", {
                variant: "success",
              });
        }
      })
      .catch((err) => {
        // Handle error if necessary
      });
  };
  const handleDeleteToken = () => {
    setApiKeyDeleteDialog(false);
    dispatch(developersApiDeleteToken())
      .unwrap()
      .then((res) => {
        if (res && res?.status === "success") {
          setShowApikeyData({
            keyToShow: "",
            keyUpdatedAt: "",
            tokenGenerated: false,
          });
          enqueueSnackbar("API Token Deleted Successfully !", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        // Handle error if necessary
      });
  };

  if (loading && !loadError) return <TableLoader />;
  else if (!loading && loadError)
    return (
      <ShowMessage
        src={IMG_ERROR}
        title="Error occurred"
        message="Something went wrong while fetching the data. Please click on the retry button to load the data again."
        sx={{
          height: 200,
          width: 200,
          my: 4,
        }}
      >
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          startIcon={<ReplayOutlinedIcon />}
          onClick={handleRetry}
          disableElevation
        >
          Retry
        </Button>
      </ShowMessage>
    );
  else if (!loadError && !loading)
    return (
      <>
        <Grid container item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper
                sx={{
                  p: 3,
                  lineHeight: 0,
                  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
                }}
              >
                {/* <Box
                                    component="iframe"
                                    src={process.env.REACT_APP_PLIVO_VIDEO}
                                    title="Voice Genie"
                                    width="100%"
                                    height={isMd ? 220 : 350}
                                    border={0}
                                    borderRadius={0.5}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                /> */}
                <img
                  src={DEVELOPER_APIS_IMG}
                  alt="Logo"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              {/* <Paper sx={{ p: 3, boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)' }}> */}
              <Card
                sx={{
                  minHeight: "300px",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 100,
                  paddingBottom: 0,
                  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CardHeader
                  variant="subtitle1"
                  sx={{
                    borderBottom: 1,
                    borderColor: "#d3d3d3",
                    paddingRight: 1,
                  }}
                  title={
                    <Grid
                      container
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ paddingLeft: 1, paddingRight: 1 }}
                    >
                      <Grid item>
                        <Typography
                          sx={{ fontSize: "1.35rem", fontWeight: "bold" }}
                        >
                          API Credentials
                        </Typography>
                      </Grid>
                      {showApikeyData?.tokenGenerated ? (
                        <Grid item display="flex" alignItems="center">
                          <DeleteIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setApiKeyDeleteDialog(true);
                            }}
                            aria-label="delete"
                            sx={{
                              "&:hover": {
                                animation: `${pop} 0.3s ease-in-out forwards`,
                              },
                            }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  }
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: 0,
                  }}
                >
                  {showApikeyData?.tokenGenerated ? (
                    <Grid container item xs={12}>
                      <Grid container mb={1}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            display="flex"
                            alignItems="center"
                            variant="subtitle1"
                            fontWeight="bold"
                            paddingLeft={1}
                            color="text.primary"
                            gutterBottom
                          >
                            WorkspaceId
                            <CustomHintPopover
                              size="small"
                              maxWidth={400}
                              transformHorizontalPosition={"left"}
                              transformVerticalPosition={"center"}
                              anchorHorizontalPosition={"right"}
                              anchorVerticalPosition={"top"}
                              hintContent={
                                "This uniquely identifies your account, this stays constant even if you regenerate the token."
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={8}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            paddingLeft={1}
                            color="text.primary"
                            gutterBottom
                          >
                            {workspaceId}
                          </Typography>
                          <IconButton
                            onClick={() => handleCopyClick(workspaceId)}
                          >
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container mb={1}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            display="flex"
                            alignItems="center"
                            variant="subtitle1"
                            fontWeight="bold"
                            paddingLeft={1}
                            color="text.primary"
                            gutterBottom
                          >
                            Created At
                            <CustomHintPopover
                              size="small"
                              maxWidth={400}
                              transformHorizontalPosition={"left"}
                              transformVerticalPosition={"center"}
                              anchorHorizontalPosition={"right"}
                              anchorVerticalPosition={"top"}
                              hintContent={
                                "This is the date when the currently shown token was generated."
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={8}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="subtitle2"
                            fontWeight="bold"
                            paddingLeft={1}
                            color="text.primary"
                            gutterBottom
                          >
                            {dayjs(showApikeyData?.keyUpdatedAt).format(
                              "YYYY-MM-DD"
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={4}>
                          <Typography
                            display="flex"
                            alignItems="center"
                            variant="subtitle1"
                            fontWeight="bold"
                            paddingLeft={1}
                            color="text.primary"
                            gutterBottom
                          >
                            API Token
                            <CustomHintPopover
                              size="small"
                              maxWidth={400}
                              transformHorizontalPosition={"left"}
                              transformVerticalPosition={"center"}
                              anchorHorizontalPosition={"right"}
                              anchorVerticalPosition={"top"}
                              hintContent={
                                "This token would be needed to authenticate when making API calls to VoiceGenie."
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={8}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              size="small"
                              htmlFor="outlined-adornment-password"
                            >
                              API Token
                            </InputLabel>
                            <OutlinedInput
                              fullWidth
                              disabled="true"
                              size="small"
                              value={showApikeyData?.keyToShow ?? ""}
                              id="outlined-adornment-password"
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item mt={2}>
                          <Button
                            onClick={() => setApiTokenReGenerateDialog(true)}
                            paddingInline={1}
                            variant="contained"
                            sx={{ minWidth: "100px", textTransform: "none" }}
                          >
                            <Typography paddingRight={1} variant="body1">
                              Regenerate Token
                            </Typography>
                            <AutorenewIcon fontSize="small" />
                          </Button>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <Typography variant="caption">
                            <strong>Note : </strong> You can use these keys to
                            call VoiceGenie APIs. For security purposes, you can
                            only see the last few characters of the token. If
                            you lose the key, you can always generate a new one.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item xs={12} height="100%">
                      <Grid item xs={12} paddingInline={1}>
                        <Typography variant="caption">
                          <ul>
                            <li>
                              <strong>
                                Generate a token to authenticate your access to
                                VoiceGenie APIs.
                              </strong>
                            </li>
                            <li>
                              <strong>WorkspaceId</strong> and
                              <strong>token</strong> provide secure and
                              authorized usage of our APIs.
                            </li>
                            <li>
                              Ensure to keep your <strong>token</strong> and
                              <strong>workspaceID</strong> confidential and use
                              them in your <strong>API requests</strong> to
                              access the full range of VoiceGenie
                              functionalities.
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          onClick={() => handleGenerateToken()}
                          paddingInline={1}
                          variant="contained"
                          sx={{ minWidth: "100px", textTransform: "none" }}
                        >
                          <Typography paddingRight={1} variant="body1">
                            Generate Token
                          </Typography>
                          <AddIcon fontSize="small" />
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
              <Paper
                sx={{
                  marginTop: isMd ? 4 : 0,
                  display: "flex",
                  justifyContent: "space-between",
                  paddingInline: 3,
                  paddingBlock: 1,
                  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Read more about this in docs.
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => openUrlInNewTab(DEVELOPERSAPI_DOCS_LINK)}
                  style={{
                    textTransform: "none",
                    color: "themetext.primary",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  Click Here
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4}>
          <CopyBlock
            initialCode={DEVELOPERSAPI_TEMPLATE?.initialCode}
            description={DEVELOPERSAPI_TEMPLATE?.description}
            linkUrl={DEVELOPERSAPI_TEMPLATE?.linkUrl}
            linkText={DEVELOPERSAPI_TEMPLATE?.linkText}
          />
        </Grid>
        <Grid container item xs={12}>
          {faqContents?.developersFaqs?.length > 0 && (
            <Container maxWidth="lg" sx={{ my: 2 }}>
              <Typography
                variant="h4"
                align="center"
                fontWeight="fontWeightBold"
                color="primary.main"
                marginBlock={2}
                mt={6}
              >
                FAQ's
              </Typography>
              {faqContents?.developersFaqs?.map((adddata, index) => {
                if (index < numAccordions) {
                  return (
                    <StyledAccordion
                      sx={{
                        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
                        my: 1,
                        borderLeft: 5,
                        borderLeftColor: "primary.main",
                      }}
                      key={index}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{adddata.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="subtitle1"
                          dangerouslySetInnerHTML={{ __html: adddata.answer }}
                        />
                      </AccordionDetails>
                    </StyledAccordion>
                  );
                }
                return null;
              })}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {faqContents?.developersFaqs?.length > numAccordions && (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
                      mt: 1,
                    }}
                    onClick={() =>
                      setNumAccordions(faqContents?.developersFaqs?.length)
                    }
                  >
                    Load More
                  </Button>
                )}
              </Box>
            </Container>
          )}
        </Grid>
        <Dialog
          PaperProps={{
            sx: {
              maxWidth: 800,
            },
          }}
          aria-labelledby="token-generation"
          open={apiKeyGenerateDialog}
          onClose={() => setApiKeyGenerateDialog(false)}
          fullWidth
        >
          <IconButton
            aria-label="close"
            onClick={() => setApiKeyGenerateDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            align="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
            fontWeight="bold"
          >
            Generated API Token
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                xs={12}
                mb={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Alert severity="info" icon={false}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      paddingLeft={1}
                      color="text.primary"
                    >
                      {generatedApikey?.length > 0
                        ? generatedApikey
                        : "Missing"}
                    </Typography>
                  </Alert>
                  <IconButton
                    sx={{ marginInline: 2 }}
                    onClick={() => handleCopyClick(generatedApikey)}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="subtitle2" align="start" color="error">
                  <>
                    <strong>Note : </strong>
                    Please save this token, you won't be able to view it again.
                    If it gets lost, you'll need to create a new one
                  </>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          PaperProps={{
            sx: {
              maxWidth: 800,
            },
          }}
          aria-labelledby="token-regeneration"
          open={apiTokenReGenerateDialog}
          onClose={() => setApiTokenReGenerateDialog(false)}
          fullWidth
        >
          <IconButton
            aria-label="close"
            onClick={() => setApiTokenReGenerateDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            align="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
            fontWeight="bold"
          >
            Re-Generate API Token
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                mb={2}
                mt={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    handleGenerateToken(true);
                    setApiTokenReGenerateDialog(false);
                  }}
                  paddingInline={1}
                  variant="contained"
                  sx={{ minWidth: "100px", textTransform: "none" }}
                >
                  <Typography variant="body1">Re-Generate</Typography>
                </Button>
                <Button
                  onClick={() => setApiTokenReGenerateDialog(false)}
                  paddingInline={1}
                  variant="outlined"
                  sx={{ minWidth: "100px", textTransform: "none" }}
                >
                  <Typography variant="body1">Cancel</Typography>
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="caption" align="start" color="error">
                  <ul>
                    <li>
                      After Re-generatation, you won't be able to able to use
                      the old token anywhere. You need to use the new generated
                      Token.
                    </li>
                    <li>
                      Once Generated, the token can not be seen for security
                      reasons, in case you lost the token, you can always
                      generate a new one.
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          PaperProps={{
            sx: {
              maxWidth: 800,
            },
          }}
          aria-labelledby="delete-token"
          open={apiKeyDeleteDialog}
          onClose={() => setApiKeyDeleteDialog(false)}
          fullWidth
        >
          <IconButton
            aria-label="close"
            onClick={() => setApiKeyDeleteDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            align="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
            fontWeight="bold"
          >
            Delete API Token
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                xs={12}
                mb={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography>
                  Do you really want to delete the API token?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="subtitle2" align="start" color="error">
                  <>
                    <strong>Note : </strong>
                    Once the token is deleted, you will not be able to use it
                    again, you will need to create a new one.
                  </>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                mt={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Button
                  color="error"
                  onClick={() => handleDeleteToken()}
                  paddingInline={1}
                  variant="contained"
                  sx={{ minWidth: "100px", textTransform: "none" }}
                >
                  <Typography marginRight={1} variant="body1">
                    Delete
                  </Typography>
                  <DeleteIcon fontSize="small" />
                </Button>
                <Button
                  onClick={() => setApiKeyDeleteDialog(false)}
                  paddingInline={1}
                  variant="contained"
                  sx={{ minWidth: "100px", textTransform: "none" }}
                >
                  <Typography marginRight={1} variant="body1">
                    Cancel
                  </Typography>
                  <CloseIcon fontSize="small" />
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  else return false;
};

export default DevelopersApi;
