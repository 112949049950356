import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import VariableInputEditor from "../MentionEditor";
import { Grid, Typography } from "@mui/material";
import CustomHintPopover from "../CustomHintPopover";

const BasicForm = ({ data, onCancel, onSave, mentionList }) => {
  const [selectedData, setSelectedData] = useState(data);
  const modalLoading = useSelector((state) => state.pageDetails.modalLoading);
  const assistants = useSelector((state) => state.assistantsDetails.assistants);
 const themeMode = useSelector((state) => state.pageDetails.themeMode);

  const editorRef = useRef(null);
  const handleChange = (changedValue) => {
    setSelectedData((prev) => ({
      ...prev,
      ...changedValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(selectedData);
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Typography
        display="flex"
        alignItems="center"
        variant="subtitle2"
        color="text.secondary"
      >
        Assistant's Name*
        <CustomHintPopover
          size="small"
          maxWidth={400}
          // hintTitle={'Bot Temperature Setting'}
          transformHorizontalPosition={"left"}
          transformVerticalPosition={"center"}
          anchorHorizontalPosition={"right"}
          anchorVerticalPosition={"top"}
          hintContent={"  What name would you like to give your assistant?"}
        />
      </Typography>
      <TextField
        size="small"
        placeholder="Enter assistant name"
        variant="outlined"
        value={selectedData?.name ?? ""}
        onChange={(e) => handleChange({ name: e.target.value })}
        inputProps={{
          maxLength: 20,
        }}
        fullWidth
        required
        sx={{ marginBottom: 1 }}
      />
      {/* <Typography
        display="flex"
        alignItems="center"
        variant="subtitle2"
        color="text.secondary"
      >
        Goal
        <CustomHintPopover
          size="small"
          maxWidth={400}
          // hintTitle={'Bot Temperature Setting'}
          transformHorizontalPosition={"left"}
          transformVerticalPosition={"center"}
          anchorHorizontalPosition={"right"}
          anchorVerticalPosition={"top"}
          hintContent={"What do you want your assistant to achieve?"}
        />
      </Typography>
      <TextField
        size="small"
        placeholder="eg : How would you like to pay missing EMIs"
        variant="outlined"
        value={selectedData?.goal ?? ""}
        rows={2}
        onChange={(e) => handleChange({ goal: e.target.value })}
        inputProps={{
          maxLength: 250,
        }}
        multiline
        fullWidth
        required
        sx={{ marginBottom: 1 }}
      /> */}
      {/* <Typography display="flex" alignItems="center" variant="subtitle2" color="text.secondary">
        Set Your Call Introductory Message
        <CustomHintPopover
          size="small"
          maxWidth={400}
          // hintTitle={'Bot Temperature Setting'}
          transformHorizontalPosition={'left'}
          transformVerticalPosition={'center'}
          anchorHorizontalPosition={'right'}
          anchorVerticalPosition={'top'}
          hintContent={"Craft a introductory message for your assistant to use during calls."}
        />
      </Typography> */}
      {/* <Grid item xs={12} component="div" sx={{ marginInline: "-10px", marginTop: "-10px", marginBottom: 1, borderRadius: "24px" }}>
        <VariableInputEditor
          formattedEnvVariables={mentionList}
          placeholder="Initial Greeting"
          value={assistants?.callOpeningMessage}
          onTextChange={htmlText => handleChange({ callOpeningMessage: htmlText })}
          editorRef={editorRef}
          style={{ margin: 10 }}
          wordLimit={200}
        />
      </Grid> */}
      <Typography
        display="flex"
        alignItems="center"
        variant="subtitle2"
        color="text.secondary"
      >
        Enter your company name
        <CustomHintPopover
          size="small"
          maxWidth={400}
          // hintTitle={'Bot Temperature Setting'}
          transformHorizontalPosition={"left"}
          transformVerticalPosition={"center"}
          anchorHorizontalPosition={"right"}
          anchorVerticalPosition={"top"}
          hintContent={"Please enter the name of your company or organization."}
        />
      </Typography>
      <TextField
        size="small"
        placeholder="eg : VoiceGenie"
        variant="outlined"
        rows={3}
        value={selectedData?.companyName ?? ""}
        onChange={(e) => handleChange({ companyName: e.target.value })}
        fullWidth
        required
        multiline
        sx={{ marginBottom: 1 }}
      />
      <Box display="flex" justifyContent="center" gap={2} pt={2}>
        <Button
          variant="outlined"
          sx={{ minWidth: 100 }}
          onClick={onCancel}
          disableElevation
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: 100,color: "themetext.primary" }}
          loading={modalLoading}
          disabled={modalLoading}
        >
          Create
        </LoadingButton>
      </Box>
    </Box>
  );
};

BasicForm.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

BasicForm.defaultProps = {
  data: {},
};

export default BasicForm;
