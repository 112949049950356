import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Box,
  CardHeader,
  TextField,
  Dialog,
  DialogContent,
  keyframes,
  DialogActions,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { CLEVERTAP_EVENT, ROUTE_PATH ,GTAGS} from "../../data/configs/constants";
import { cleverTap } from "../../data/configs/clevertap";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorBoundary from "../ErrorBoundary";
import TestAssistantForm from "../TestAssistantForm";
import { useState } from "react";
import CustomHintPopover from "../CustomHintPopover";
export default function AssistantCard({
  onClick,
  assistant = {},
  handleEditAssistant,
  handleDeleteAssistant,
}) {
  const pop = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;
  const [dialogData, setDialogData] = useState({});
  const [dialogDataForDelete, setDialogDataForDelete] = useState(false);
  const { pathname } = useLocation();
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const user = useSelector((state) => state.authDetails.user);
  const workspaceId = useSelector(
    (state) => state.authDetails?.user?.workspace
  );
  function strShortener(str = "") {
    if (str.length > 80) {
      return `${str.slice(0, 80)}...`;
    } else {
      return str;
    }
  }

  const handleTalkToAgentClick = (e) => {
    e.stopPropagation();
    window.gtag("event",GTAGS.ASSISTANT_TEST_BUTTON_CLICKED,{
      userID:user.userID,
      WorkspaceID: workspaceId,
      buttonName: "Test Your Assistant",
    })
    cleverTap.track(CLEVERTAP_EVENT.BUTTON_CLICK, {
      workspaceId,
      buttonName: "Test Your Assistant",
      onboarding: pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT,
    });
    setDialogData({
      showTestAssistant: true,
      testAssistant: {},
    });
  };

  const handleCloseDialoge = (e, reason) => {
    if (reason !== "backdropClick") setDialogData({});
  };
  const handleCloseDialogeForDelete = (e) => {
    setDialogDataForDelete(false);
  };

  return (
    <>
      <Card
        onClick={onClick}
        sx={{
          minHeight: "182px",
          display: "flex",
          flexDirection: "column",
          cursor: onClick ? "pointer" : "default", // Corrected the condition
          minWidth: 100,
          paddingBottom: 1,
          paddingLeft: 1,
          boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <CardHeader
          variant="subtitle1"
          sx={{
            borderBottom: 1,
            paddingBlock: 1,
            paddingInline: 0,
            borderColor: "#d3d3d3",
          }}
          title={
            <Grid
              container
              alignItems="center"
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            >
              <Grid item xs={10}>
                <Typography sx={{ fontSize: "1.35rem", fontWeight: "bold" }}>
                  {assistant?.name && assistant?.name !== ""
                    ? assistant?.name
                    : "The Bot"}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={2}
                justifyContent="center"
                alignItems="center"
              >
                {/* <Grid item>
                  <SettingsIcon
                    fontSize="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditAssistant();
                    }}
                    sx={{
                      "&:hover": {
                        animation: `${pop} 0.3s ease-in-out forwards`,
                      },
                    }}
                  />
                </Grid> */}
                <Grid item>
                  <DeleteIcon
                    fontSize="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDialogDataForDelete(true);
                    }}
                    aria-label="delete"
                    sx={{
                      "&:hover": {
                        animation: `${pop} 0.3s ease-in-out forwards`,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent sx={{ flexGrow: 1, padding: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", paddingLeft: 1 }}
                color="text.primary"
              >
                Goal
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingRight: 1 }}>
              <Typography
                variant="body2"
                paragraph="none"
                marginBottom={0}
                marginBlock={0.5}
                sx={{ paddingLeft: 1 }}
              >
                {assistant?.goal ? (
                  strShortener(assistant?.goal)
                ) : (
                  <Typography
                    variant="caption"
                    color="main.secondary"
                    sx={{ fontWeight: "bold" }}
                  >
                    Add assistant goal
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Grid container display="flex" gap={1}>
            <Grid>
              <Button size="small" variant="contained" sx={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  display="flex"
                  alignItems="center"
                  sx={{color: "themetext.primary"}}
                >
                  Setup
                </Typography>
              </Button>
            </Grid>
            <Grid>
              <Tooltip
                title={
                  !assistant.script?.trim() ||
                  assistant.script === "<p><br></p>"
                    ? "Assistant script is required to test"
                    : !assistant.goal?.trim() ||
                      assistant.goal === "<p><br></p>"
                    ? "Assistant goal is required to test"
                    : !assistant.callOpeningMessage?.trim() ||
                      assistant.callOpeningMessage === "<p><br></p>"
                    ? "Call opening message is required to test"
                    : ""
                }
              >
                <span>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ width: "100%" }}
                    disabled={
                      !assistant?.script?.trim() ||
                      assistant?.script === "<p><br></p>" ||
                      !assistant?.goal?.trim() ||
                      assistant?.goal === "<p><br></p>" ||
                      !assistant?.callOpeningMessage?.trim() ||
                      assistant?.callOpeningMessage === "<p><br></p>"
                    }
                    onClick={handleTalkToAgentClick}
                  >
                    <Typography
                      fontWeight="bold"
                      variant="caption"
                      display="flex"
                      alignItems="center"
                    >
                      Test
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <Dialog
        PaperProps={{
          sx: {
            overflowY: "visible",
          },
        }}
        aria-labelledby="vg-test-assistant"
        open={
          dialogData.showTestAssistant || dialogData.showVoiceAccent || false
        }
        onClose={handleCloseDialoge}
        fullWidth
      >
        <DialogContent sx={{ overflowY: "visible" }}>
          {dialogData?.showTestAssistant && (
            <ErrorBoundary onErrorCallback={handleCloseDialoge}>
              <TestAssistantForm
                assistantId={assistant?.assistantId}
                onCancel={handleCloseDialoge}
              />
            </ErrorBoundary>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        aria-labelledby="vg-delete-assistant"
        open={dialogDataForDelete}
        onClose={handleCloseDialogeForDelete}
        fullWidth
      >
        <DialogTitle fullWidth sx={{ justifyContent: "center" }}>
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <Typography align="center">
            Do you really want to delete this
            <strong>({assistant?.name})</strong> assistant?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 2, justifyContent: "center" }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleCloseDialogeForDelete();
            }}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteAssistant();
              handleCloseDialogeForDelete();
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
