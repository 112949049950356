import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Stack,
  FormControl,
  FormLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomHintPopover from "../../../CustomHintPopover";
import * as _ from "lodash";
const ReadOnlyDataDialog = ({ open, onClose, data, type }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: "0px", paddingTop: 2, paddingInline: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{type === "webhook" ? "Webhook Details" : "Transfer to Agent Details"}</Typography>
          <IconButton size="small" edge="end" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: 1 }}>
        {/* Name Field */}
        <FormControl size="small" fullWidth>
          <FormLabel sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color="text.primary"
              display="flex"
              alignItems="center"
              variant="caption"
              fontWeight="bold"
            >
              Name
            </Typography>
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition="left"
              transformVerticalPosition="top"
              anchorHorizontalPosition="right"
              anchorVerticalPosition="top"
              hintContent={<p>This is the name of the action.</p>}
            />
          </FormLabel>
          <Typography variant="body2" color="textSecondary">
            {data?.label ?? data?.name ?? "Name Not Given"}
          </Typography>
        </FormControl>

        {/* Description Field */}
       {!_.isEmpty(data.description) ? ( <FormControl fullWidth>
          <FormLabel sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              display="flex"
              alignItems="center"
              variant="caption"
              fontWeight="bold"
              color="text.primary"
            >
              Description
            </Typography>
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition="left"
              transformVerticalPosition="top"
              anchorHorizontalPosition="right"
              anchorVerticalPosition="top"
              hintContent={<p>This is a description of the action.</p>}
            />
          </FormLabel>
          <Typography variant="body2" color="textSecondary">
            {data.description}
          </Typography>
        </FormControl>
) : null}
        {/* Additional Fields Based on Type */}
        {type === "transfer_to_agent" && (
          <FormControl size="small" fullWidth>
            <FormLabel sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                display="flex"
                alignItems="center"
                variant="caption"
                fontWeight="bold"
                color="text.primary"
              >
                Phone Number
              </Typography>
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition="left"
                transformVerticalPosition="top"
                anchorHorizontalPosition="right"
                anchorVerticalPosition="top"
                hintContent={<p>This is the phone number to transfer the call to.</p>}
              />
            </FormLabel>
            <Typography variant="body2" color="textSecondary">
              {data.data.phoneNumbers}
            </Typography>
          </FormControl>
        )}

        {type === "webhook" && (
          <FormControl size="small" fullWidth>
            <FormLabel sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                display="flex"
                alignItems="center"
                variant="caption"
                fontWeight="bold"
                color="text.primary"
              >
                Webhook URL
              </Typography>
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition="left"
                transformVerticalPosition="top"
                anchorHorizontalPosition="right"
                anchorVerticalPosition="top"
                hintContent={<p>This is the Webhook URL that will be called.</p>}
              />
            </FormLabel>
            <Typography variant="body2" color="textSecondary">
              {data.data.url}
            </Typography>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions sx={{ paddingInline: 3, marginBottom: 1 }}>
        <Button
          size="small"
          sx={{
            borderRadius: "10px",
            minWidth: "80px",
            textTransform: "none",
          }}
          onClick={onClose}
          variant="outlined"
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReadOnlyDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,  // Data object contains all necessary details (name, description, etc.)
  type: PropTypes.oneOf(["transfer_to_agent", "webhook"]).isRequired,  // Type indicates whether it's for transfer_to_agent or webhook
};

export default ReadOnlyDataDialog;
