import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Stack,
  FormControl,
  FormLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomHintPopover from "../../../CustomHintPopover";
import PhoneInput from "react-phone-input-2";
import { enqueueSnackbar } from "notistack";

const TransferToAgentDialog = ({
  open,
  onClose,
  TransferToActionCreateAction,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [transferTo, setTransferTo] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!open) {
      setName("");
      setDescription("");
      setTransferTo("");
      setError(false);
    }
  }, [open]);
  
  const handleSave = () => {
    if(!/^\+?\d+$/.test(transferTo) || transferTo?.length < 3){
      enqueueSnackbar(`Please enter a valid Phone Number.`, {
        variant: "error",
      });

      setError(true);
      return;
    }
    if (name?.trim()?.length < 2 ) {
      // Simple phone number validation
      setError(true);
      return;
    }
    TransferToActionCreateAction(transferTo, name, description);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: "0px", paddingTop: 2, paddingInline: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Transfer Call</Typography>
          <IconButton size="small" edge="end" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 1 }}>
        {/* Name Field */}
        <FormControl size="small" fullWidth>
          <FormLabel sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color="text.primary"
              display="flex"
              alignItems="center"
              variant="caption"
              fontWeight="bold"
            >
              Name
            </Typography>
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition="left"
              transformVerticalPosition="top"
              anchorHorizontalPosition="right"
              anchorVerticalPosition="top"
              hintContent={<p>Enter a name for this action.</p>}
            />
          </FormLabel>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
              },
            }}
            size="small"
            placeholder="transfer_call ( Minimum 2 characters )"
            variant="outlined"
            minRows={1}
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{
              maxLength: 300,
            }}
            required
            fullWidth
          />
        </FormControl>

        {/* Description Field */}
        <FormControl fullWidth>
          <FormLabel sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              display="flex"
              alignItems="center"
              variant="caption"
              fontWeight="bold"
              color="text.primary"
            >
              Description
            </Typography>
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition="left"
              transformVerticalPosition="top"
              anchorHorizontalPosition="right"
              anchorVerticalPosition="top"
              hintContent={
                <p>Provide a detailed description for this action.</p>
              }
            />
          </FormLabel>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
              },
            }}
            size="small"
            placeholder="Briefly describe this function for team reference (e.g., 'Transfer to agent when user requests help'). This is not a trigger or prompt."
            variant="outlined"
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{
              maxLength: 300,
            }}
            fullWidth
          />
          {description?.trim()?.length >= 300 && (
            <Typography color="error" variant="caption" paddingInline={2}>
              Only 300 characters allowed for the description.
            </Typography>
          )}
        </FormControl>

        {/* Transfer to Field */}
        <FormControl size="small" fullWidth>
          <FormLabel sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              display="flex"
              alignItems="center"
              variant="caption"
              fontWeight="bold"
              color="text.primary"
            >
              Number
            </Typography>
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition="left"
              transformVerticalPosition="top"
              anchorHorizontalPosition="right"
              anchorVerticalPosition="top"
              hintContent={
                <p>Specify the phone number to transfer the call to.</p>
              }
            />
          </FormLabel>
          <PhoneInput
            placeholder="Enter your phone number"
            enableSearch
            country={"us"}
            value={transferTo}
            onChange={(value) => setTransferTo(value)}
            size="small"
            containerStyle={{
              fontSize: "20px",
              marginBottom: "10px",
            }}
            inputStyle={{
              width: "100%",
              borderRadius: "24px",
              minHeight: "38px",
              fontSize: "initial",
            }}
            inputProps={{
              required: true,
              autoFocus: true,
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ paddingInline: 3, marginBottom: 1 }}>
        <Button
          size="small"
          sx={{
            borderRadius: "10px",
            minWidth: "80px",
            textTransform: "none",
          }}
          onClick={onClose}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          size="small"
          sx={{
            borderRadius: "10px",
            minWidth: "80px",
            textTransform: "none",
          }}
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!name || name?.trim()?.length < 2 }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TransferToAgentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TransferToAgentDialog;
