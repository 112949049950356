import { LOCAL_STORAGE } from "../../configs/constants"
import { getDataFromLocalStorage } from "../../configs/utils"

const initialState = {
  user: getDataFromLocalStorage(LOCAL_STORAGE.APP_USER, null),
  token: getDataFromLocalStorage(LOCAL_STORAGE.AUTH_TOKEN, ""),
  plans: [],
  addOnPlans: [],
  addOnPlansWholeData: [],
  oneTimePlans: [],
  bannerInfo: [] ,
  dynamicBanner:{},
  subscription: {},
  currentAddOnPlans: [],
  userQuota: [],
  demoCallSuccessfull: false,
}

export default initialState
