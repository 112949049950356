import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
const WebhookSelectDialog = ({
  isOpen,
  onClose,
  availableAgentQuota,
  availableToChooseWebhookActionsArray,
  handleOptionClick,
  handleAddFromExistingWebhook,
}) => {
  const themeMode = useSelector((state) => state.pageDetails.themeMode);

  return (
    <Dialog
      PaperProps={{
        style: {
          maxWidth: "1400px",
          width: "600px",
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography align="center" variant="h6" fontWeight="bold" >
          Add Webhook
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} align="center" mt={2} mb={6}>
          <Button
            style={{ minWidth: "200px", paddingBlock: 4, paddingInline: 15 }}
            variant="contained"
            onClick={() => {
              handleOptionClick("Webhook");
              onClose();
            }}
            startIcon={<AddIcon  sx={{ color: "themetext.primary"}}/>}
            // disabled={availableAgentQuota < 1}
          >
            <Typography align="center" variant="body1" textTransform="none" sx={{ color: "themetext.primary"}}>
              Add Webhook
            </Typography>
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: 2, justifyContent: "center" }}>
          {availableToChooseWebhookActionsArray?.length !== 0 && (
            <FormControl style={{ width: "100%" }}>
              <Select
                value=""
                displayEmpty
                sx={{
                  "& .MuiOutlinedInput-input": {
                    paddingBlock: 1,
                  },
                  justifyContent: "center",
                }}
                fullWidth
              >
                <MenuItem
                  value=""
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <em>Select Existing Webhooks</em>
                </MenuItem>
                {availableToChooseWebhookActionsArray?.map((item) => (
                  <MenuItem
                    key={item._id}
                    value={item._id}
                    onClick={() => {
                      onClose();
                      handleAddFromExistingWebhook(item._id);
                    }}
                  >
                    {item?.data?.url}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WebhookSelectDialog;
