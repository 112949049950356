import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import initialState from "./initialState";
import { fetchDataAndProceedWithToolkit } from "../helpers";
import {
  CREATE_CONTACT_LIST,
  EDIT_CONTACT_LIST,
  GET_ALL_CONTACT_LIST,
  DELETE_CONTACT_LIST,
  ADD_CONTACTS_VIA_FILE,
  GET_CONTACTS,
  DELETE_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  CREATE_CONTACT_FORM_LIST,
  UPDATE_DYNAMIC_FIELD_VALUES_DEMO_CAMPAIGN,
} from "./api";
import { METHOD_TYPES } from "../../configs/constants";

const contactListSlice = createSlice({
  name: "contactList",
  initialState,
  reducers: {
    updateContactListState(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createContactList.fulfilled, (state, { payload }) => {
        if (payload?.data?.contactList)
          state.contactList = [...state.contactList, payload.data.contactList];
      })
      .addCase(getAllContactList.pending, (state) => {
        state.loadError = false;
        state.loading = true;
      })
      .addCase(getAllContactList.fulfilled, (state, { payload }) => {
        if (payload?.data?.contactLists)
          state.contactList = payload.data.contactLists;
        state.loadError = false;
        state.loading = false;
      })
      .addCase(getAllContactList.rejected, (state) => {
        state.loadError = true;
        state.loading = false;
      })
      .addCase(editContactList.fulfilled, (state, { payload }) => {
        if (payload?.data?.contactList?.contactListId) {
          const index = state.contactList.findIndex(
            (item) =>
              item.contactListId === payload.data.contactList.contactListId
          );
          if (index > -1) {
            state.contactList = [
              ...state.contactList.slice(0, index),
              payload.data.contactList,
              ...state.contactList.slice(index + 1),
            ];
          }
        }
      })
      .addCase(deleteContactList.fulfilled, (state, { payload }) => {
        if (payload?.data?.contactList?.contactListId) {
          const index = state.contactList.findIndex(
            (item) =>
              item.contactListId === payload.data.contactList.contactListId
          );
          if (index > -1) {
            state.contactList = [
              ...state.contactList.slice(0, index),
              ...state.contactList.slice(index + 1),
            ];
          }
        }
      })
      .addCase(addContactsViaFile.fulfilled, (state, { payload }) => {
        if (payload?.data?.contactList?.contactListId) {
          const index = state.contactList.findIndex(
            (item) =>
              item.contactListId === payload.data.contactList.contactListId
          );
          if (index > -1) {
            state.contactList = [
              ...state.contactList.slice(0, index),
              payload.data.contactList,
              ...state.contactList.slice(index + 1),
            ];
          }
        }
      })
      .addCase(getContacts.pending, (state, { meta }) => {
        if (state.contacts.length === 0) {
          state.contactsLoadError = false;
          state.contactsLoading = true;
        }
      })
      .addCase(getContacts.fulfilled, (state, { payload }) => {
        if (payload?.data?.contactList && payload?.data?.contactListData) {
          const index = state.contactList.findIndex(
            (item) =>
              item.contactListId === payload.data.contactList.contactListId
          );
          if (index > -1) state.contactList[index] = payload.data.contactList;
          state.selectedContactList = payload.data.contactList;
          state.contacts = payload.data.contactListData;
          state.contactsTotalCount = payload.data.documentCount;
          state.contactsLoadError = false;
          state.contactsLoading = false;
        }
      })
      .addCase(updateContact.fulfilled, (state, { meta, payload }) => {
        if (payload?.data?.updatedContactListData) {
          const index = state.contacts.findIndex(
            (item) => item.customerNumber === meta?.arg?.filter?.customerNumber
          );
          if (index > -1) {
            state.contacts[index] = payload?.data?.updatedContactListData;
          }
        }
      });
    // .addCase(deleteContact.fulfilled, (state, { meta }) => {
    //   const index = state.contacts.findIndex(
    //     (item) => item.customerNumber === meta?.arg?.filter?.customerNumber
    //   )
    //   if (index > -1) {
    //     state.contacts = [
    //       ...state.contacts.slice(0, index),
    //       ...state.contacts.slice(index + 1),
    //     ]
    //     state.selectedContactList = {
    //       ...state.selectedContactList,
    //       count: state.selectedContactList.count - 1,
    //     }
    //   }
    // })
  },
});

export const createContactList = createAsyncThunk(
  "contactList/createContactList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_CONTACT_LIST,
        data,
        method: METHOD_TYPES.POST,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
);
export const updateDynamicFieldValuesDemoCampaign = createAsyncThunk(
  "contactList/updateDynamicFieldValuesDemoCampaign",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_DYNAMIC_FIELD_VALUES_DEMO_CAMPAIGN,
        data,
        method: METHOD_TYPES.POST,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
);

export const editContactList = createAsyncThunk(
  "contactList/updateContactList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: EDIT_CONTACT_LIST,
        data,
        method: METHOD_TYPES.PUT,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
);

export const getAllContactList = createAsyncThunk(
  "contactList/getAllContactList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ALL_CONTACT_LIST,
        data,
        loader: false,
      },
      helpers
    )
);

export const deleteContactList = createAsyncThunk(
  "contactList/deleteContactList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_CONTACT_LIST,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
);

export const addContactsViaFile = createAsyncThunk(
  "contactList/addContactsViaFile",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: ADD_CONTACTS_VIA_FILE,
        data,
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        modalLoader: true,
        timeout: 0,
        forceFEHideEnquebar: true,
        loader: false,
      },
      helpers
    )
);

export const getContacts = createAsyncThunk(
  "contactList/getContacts",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_CONTACTS,
        data,
        loader: data.skip !== 0,
      },
      helpers
    )
);
export const getCreateContactFormList = createAsyncThunk(
  "contactList/getCreateContactFormList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_CONTACT_FORM_LIST,
        method: METHOD_TYPES.GET,
        data,
      },
      helpers
    )
);

export const createContact = createAsyncThunk(
  "contactList/createContact",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_CONTACT,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const updateContact = createAsyncThunk(
  "contactList/updateContact",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_CONTACT,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
);

export const deleteContact = createAsyncThunk(
  "contactList/deleteContact",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_CONTACT,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
);

export const { updateContactListState } = contactListSlice.actions;
export default contactListSlice.reducer;
